<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <div class="page-header">
      <h1>{{ $t("Liste des avoirs") }}</h1>
      <div class="total">{{ getTotalRowavoir }} {{ $t("AVOIR") }}</div>
    </div>

    <div class="content">
      <div class="content-header">
        <searchBar
          :searchText="$t('SEARCH')"
          :searchFunc="all_avoirs"
          :filtre="{
            entreprise: filtre_entreprise ? filtre_entreprise.id : null,
          }"
          :showFiltre="isSuperAdmin"
          @returnValue="updateSearch($event)"
        ></searchBar>

        <b-modal id="entrepriseModal" ref="entrepriseModal">
          <template #modal-header="{ close }">
            <h5>{{ $t("COMPANY_ID") }}</h5>
            <b-button size="sm" @click="close()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="17.028"
                height="17.028"
                viewBox="0 0 17.028 17.028"
              >
                <path
                  id="Icon_material-close"
                  data-name="Icon material-close"
                  d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                  transform="translate(-7.5 -7.5)"
                  fill="#393939"
                />
              </svg>
            </b-button>
          </template>

          <form @submit.prevent="hideModal('entrepriseModal')">
            <div class="center">
              <b-form-group :label="$t('COMPANY_ID')" v-if="isSuperAdmin">
                <searchInput
                  :list="getAllentreprises"
                  :loader="getentrepriseLoading"
                  label="lib"
                  :searchFunc="all_entreprises"
                  @searchfilter="selectentreprise($event)"
                ></searchInput>
              </b-form-group>
            </div>
          </form>
          <template #modal-footer>
            <div class="actionModel">
              <b-button variant="success" @click="setup(true)">
                <div class="block-spinner">
                  {{ $t("Appliquer") }}
                </div>
              </b-button>
            </div>
          </template>
        </b-modal>
      </div>
      <div class="filtre">
        <div class="productTrie" v-if="currententreprise.lib != null">
          {{ currententreprise.lib }}
          <font-awesome-icon
            icon="times-circle"
            class="icons"
            @click="selectentreprise()"
          />
        </div>
      </div>
      <div class="table-rapport-style w-100">
        <table>
          <thead>
            <tr>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("AVOIR") }} N°
              </th>
              <th>{{ $t("INVOICE") }}/{{ $t("ACOMPTE") }} N°</th>
              <th v-if="isSuperAdmin">
                {{ $t("COMPANY") }}
              </th>
              <th>{{ $t("TOTAL TTC") }} N°</th>
              <th style="border-radius: 0px 5px 0px 0px">
                {{ $t("CREATED_AT") }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              role="row"
              class="ligneNormale"
              v-for="(data, i) in getAllavoirs"
              :key="i"
              @click.prevent.stop="handleAcompte(data)"
            >
              <td aria-colindex="1" role="cell">
                {{ data.numero_sequence }}
              </td>
              <td aria-colindex="1" role="cell">
                {{
                  data.acompte_id
                    ? data.acompte.num
                    : data.facture.numerofactures
                }}
              </td>
              <td aria-colindex="3" role="cell" v-if="isSuperAdmin">
                {{ data.entreprise.lib }}
              </td>
              <td role="cell" v-if="data.acompte_id">
                {{ data.acompte.amount | financial }}
              </td>
              <td role="cell" v-else>
                {{ data.document.total_TTC | financial }}
              </td>
              <td>
                {{ moment(data.created_at).format("DD-MM-YYYY H:mm") }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <b-modal id="acompteModel" ref="acompteModel" size="xl">
        <template #modal-header="{ close }" v-if="avoirDetail">
          <h5>
            {{ $t("AVOIR") }}
            {{ avoirDetail.numero_sequence }}
          </h5>
          <b-button size="sm" @click="close()">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17.028"
              height="17.028"
              viewBox="0 0 17.028 17.028"
            >
              <path
                id="Icon_material-close"
                data-name="Icon material-close"
                d="M24.528,9.215,22.813,7.5l-6.8,6.8-6.8-6.8L7.5,9.215l6.8,6.8-6.8,6.8,1.715,1.715,6.8-6.8,6.8,6.8,1.715-1.715-6.8-6.8Z"
                transform="translate(-7.5 -7.5)"
                fill="#393939"
              />
            </svg>
          </b-button>
        </template>
        <div v-if="avoirDetail" class="modal-test">
          <div class="part justify">
            <div class="divborder">
              <p>{{ $t("COORDONNEES_FACTURE") }}</p>
            </div>
            <div class="left">
              <ul>
                <h2 class="textstyle">{{ avoirDetail.entreprise.lib }}</h2>
                <span class="adressinfo">
                  <span class="info textstyle">
                    {{ avoirDetail.entreprise.rue }}
                  </span>
                  <span class="info textstyle">
                    {{ avoirDetail.entreprise.cp }}
                    {{ avoirDetail.entreprise.ville }}
                  </span>
                </span>
                <li>
                  <span class="entrepriseinfo">{{ $t("SIRET") }}</span>
                  <span class="textstyle">{{
                    avoirDetail.entreprise.siret
                  }}</span>
                </li>
                <li>
                  <span class="entrepriseinfo">{{ $t("NUM_TVA") }}</span>
                  <span class="textstyle">{{
                    avoirDetail.entreprise.num_tva
                  }}</span>
                </li>

                <li>
                  <span class="entrepriseinfo">{{ $t("TEL") }}</span>
                  <span>{{ avoirDetail.entreprise.phone ? avoirDetail.entreprise.phone.formated_phone : '' }}</span>
                </li>
                <li>
                  <span class="entrepriseinfo">{{ $t("OBJECT") }}</span>
                  <span v-if="avoirDetail.acompte_id"
                    >{{ $t("AVOIR_ACOMPTE") }}
                    {{ avoirDetail.acompte.num }}
                  </span>
                  <span v-else
                    >{{ $t("AVOIR_FACTURE") }}
                    {{ avoirDetail.facture.numerofactures }}
                  </span>
                </li>
              </ul>
            </div>
            <div class="right">
              <h1>{{ $t("AVOIR") }}</h1>
              <div class="infonum">
                <span>N°{{ avoirDetail.numero_sequence }}</span>
              </div>

              <h2 class="textstyle" v-if="avoirDetail.user">
                {{ avoirDetail.user.prenom }} {{ avoirDetail.user.nom }}
              </h2>
            </div>
            <div class="divborder">
              <p>{{ $t("DETAILS_FACTURE") }}</p>
            </div>
          </div>
          <template v-if="avoirDetail.acompte_id">
            <div class="table-rapport-style mt-2" v-if="avoirDetail.acompte">
              <table class="accompteTable">
                <thead>
                  <tr>
                    <th style="border-radius: 5px 0px 0px 0px">
                      {{ $t("WORDING") }}
                    </th>
                    <th>{{ $t("ACOMPTE") }} N°</th>
                    <th style="border-radius: 0px 5px 0px 0px">
                      {{ $t("TOTAL TTC") }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr role="row" class="ligneNormale">
                    <td>{{ $t("ACOMPTE") }}</td>
                    <td>{{ avoirDetail.acompte.num }}</td>
                    <td>{{ avoirDetail.acompte.amount | financial }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </template>
          <template v-else>
            <div
              v-for="(groupe, x) in avoirDetail.document.groupeLigneDocument"
              :key="x"
              class="group"
            >
              <div class="piece">
                <div>
                  <p>{{ $t("PIECE_") }}:</p>
                  <p class="textstyle" style="font-weight: 600">
                    {{ groupe.piece }}
                  </p>
                </div>
                <div class="icone-down-up">
                  <p v-if="!showGroup[x].bool">{{ $t("TOTAL_PIECE_TTC") }}</p>
                  <span v-if="!showGroup[x].bool">
                    {{ groupe.total_TTC | financial }}
                  </span>
                  <b-button size="sm" @click="closeGroup(x)">
                    <font-awesome-icon
                      icon="chevron-down"
                      class="icons"
                      v-if="!showGroup[x].bool"
                    />
                    <font-awesome-icon icon="chevron-up" class="icons" v-else />
                  </b-button>
                </div>
              </div>
              <div class="part" v-show="showGroup[x].bool">
                <ul>
                  <li class="traveauxtitle">
                    <span
                      class="title"
                      style="font-weight: 600,font-size: 14px;"
                      >{{ $t("WORK") }} :
                    </span>
                    <span class="textstyle">{{ groupe.traveaux }}</span>
                  </li>
                </ul>
                <table class="tabledonne">
                  <thead>
                    <tr>
                      <th style="border-radius: 5px 0px 0px 0px">
                        {{ $t("REF") }}
                      </th>
                      <th>{{ $t("WORDING") }}/{{ $t("DESC") }}</th>
                      <th>{{ $t("QTE") }}</th>
                      <th>{{ $t("PUHT") }}</th>
                      <th>
                        {{ $t("TOTALHT") }}
                      </th>
                      <th style="border-radius: 0px 5px 0px 0px">
                        {{ $t("VAT") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(product, y) in avoirDetail.document
                      .GroupeLigneDocument_produit"
                    :key="y"
                  >
                    <tr
                      v-if="
                        groupe.id == product.groupelignedocument_id &&
                        readActivated[y]
                      "
                    >
                      <td>
                        {{ product.ref ? product.ref : "-" }}
                      </td>

                      <td class="blocklibdesc">
                        <div class="libblock">
                          {{ product.lib ? product.lib : "" }}
                        </div>
                        <div
                          class="descblock"
                          v-if="product.desc"
                          :class="{ 'auto-heigth': readActivated[y].bool }"
                        >
                          {{
                            readActivated[y].bool
                              ? product.desc
                              : product.desc.slice(0, 105)
                          }}
                          <span
                            class="read"
                            v-if="product.desc.length > 105"
                            @click="
                              readActivated[y].bool = !readActivated[y].bool
                            "
                          >
                            ...{{
                              readActivated[y].bool ? $t("LESS") : $t("MORE")
                            }}
                          </span>
                        </div>
                      </td>
                      <td>{{ product.quantite | financial(true) }}</td>
                      <td>{{ product.unit_price | financial }}</td>
                      <td>
                        {{
                          (product.unit_price * product.quantite) | financial
                        }}
                      </td>
                      <td>{{ product.tva }} %</td>
                    </tr>
                  </tbody>
                </table>
                <div class="flexEnd">
                  <div class="sousTraitant">
                    <p v-if="groupe.sous_traitant">
                      Sous-traitant: {{ groupe.sous_traitant.name }}, SIRET:
                      {{ groupe.sous_traitant.num_siret }}, Num RGE:
                      {{ groupe.sous_traitant.num_rge }}
                    </p>
                  </div>
                  <div class="subtable">
                    <table class="complet">
                      <tr>
                        <td class="thead">
                          {{ $t("TOTAL_HT") }}
                        </td>
                        <td>{{ groupe.total_HT | financial }}</td>
                      </tr>
                      <tr v-if="groupe.remise">
                        <td class="thead">
                          {{ $t("REMISE") }}
                        </td>
                        <td>{{ groupe.remise | financial }}</td>
                      </tr>
                      <tr v-if="groupe.remise">
                        <td class="thead">
                          {{ $t("TOTALREMISE") }}
                        </td>
                        <td>{{ groupe.total_HT_with_remise | financial }}</td>
                      </tr>
                      <template v-if="groupe.tva_groupes.length">
                        <tr
                          v-for="tva in groupe.tva_groupes"
                          :key="'tva' + tva.id"
                        >
                          <td class="thead">TVA ({{ tva.tva }}%)</td>
                          <td style="border-radius: 0px 5px 0px 0px">
                            {{ tva.tva_montant | financial }}
                          </td>
                        </tr>
                      </template>
                      <tr>
                        <td class="thead">
                          {{ $t("TOTAL_PIECE_TTC") }}
                        </td>
                        <td>{{ groupe.total_TTC | financial }}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="flexEndTotale">
              <div class="subtableTotale">
                <table class="completTotale">
                  <tr>
                    <td class="theadTotale">
                      {{ $t("TOTAL_HT") }}
                    </td>
                    <td>
                      {{ avoirDetail.document.total_HT | financial }}
                    </td>
                  </tr>
                  <tr v-if="avoirDetail.document.remise_value">
                    <td class="theadTotale">
                      {{ $t("REMISE") }}
                    </td>
                    <td>
                      {{ avoirDetail.document.remise_total | financial }}
                    </td>
                  </tr>
                  <tr v-if="avoirDetail.document.remise_value">
                    <td class="theadTotale">
                      {{ $t("TOTALREMISE") }}
                    </td>
                    <td>
                      {{
                        avoirDetail.document.total_ht_with_remise | financial
                      }}
                    </td>
                  </tr>
                  <template v-if="avoirDetail.document.tva_document.length">
                    <tr
                      v-for="tva in avoirDetail.document.tva_document"
                      :key="'tva' + tva.id"
                    >
                      <td class="theadTotale">TVA({{ tva.tva }}%)</td>
                      <td style="border-radius: 0px 5px 0px 0px">
                        {{ tva.tva_montant | financial }}
                      </td>
                    </tr>
                  </template>
                  <tr>
                    <td class="theadTotale">{{ $t("Total TTC") }}</td>
                    <td>
                      {{ avoirDetail.document.total_TTC | financial }}
                    </td>
                  </tr>
                  <template v-if="avoirDetail.document.with_aide">
                    <tr v-if="avoirDetail.document.montant_maprimrenov">
                      <td class="theadTotale">
                        {{ $t("MAPRIME") }}
                      </td>
                      <td>
                        {{
                          avoirDetail.document.montant_maprimrenov | financial
                        }}
                      </td>
                    </tr>
                    <tr v-if="avoirDetail.document.montant_cee">
                      <td class="theadTotale">
                        {{ $t("CEE") }}
                      </td>
                      <td>
                        {{ avoirDetail.document.montant_cee | financial }}
                      </td>
                    </tr>
                  </template>

                  <tr
                    v-if="
                      avoirDetail.document.montant_maprimrenov ||
                      avoirDetail.document.montant_cee
                    "
                  >
                    <td class="theadTotale">
                      {{ $t("Total à payer") }}
                    </td>
                    <td>{{ avoirDetail.document.total_to_pay | financial }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </template>
        </div>

        <template #modal-footer>
          <div class="buttondevis">
            <b-button
              variant="success"
              @click="openAcompte()"
              v-if="avoirDetail.acompte_id"
            >
              <div class="block-spinner">
                {{ avoirDetail.acompte.num }}
              </div>
            </b-button>
            <b-button variant="success" @click="openfac()" v-else>
              <div class="block-spinner">
                {{ avoirDetail.document.facture.numerofactures }}
              </div>
            </b-button>

            <b-button variant="danger" @click="generate()">
              <div class="block-spinner">
                {{ $t("DOWNLOAD") }}
                <div v-if="getAvoirLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </div>
            </b-button>
          </div>
        </template>
      </b-modal>

      <b-pagination
        v-if="search == ''"
        v-model="page"
        :total-rows="getTotalRowavoir"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
      <b-pagination
        v-else
        v-model="page"
        :total-rows="getTotalRowavoir"
        :per-page="perPage"
        aria-controls="my-table"
        pills
        last-number
        first-number
        align="center"
        size="sm"
        @change="pagination"
        class=""
      ></b-pagination>
    </div>

    <div class="text-center center-spin">
      <b-spinner
        v-if="getAvoirLoading"
        variant="primary"
        label="Spinning"
        class="big-spin"
      ></b-spinner>
    </div>
    <CommentMarche></CommentMarche>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { domains } from "@/environment";
import searchBar from "../components/ui/searchBar.vue";
import searchInput from "@/components/ui/searchInput";
import CommentMarche from "../components/commentMarche.vue";
import formatMoney from "accounting-js/lib/formatMoney.js";

export default {
  components: {
    searchBar,
    searchInput,
    CommentMarche,
  },
  data() {
    return {
      search: "",
      avoirDetail: null,
      page: 1,
      perPage: 10,
      currententreprise: {
        id: null,
      },
      filtre_entreprise: {
        id: null,
      },
      box: "",
      downloadUrl: domains.download,
      pdfInfo: null,
      readActivated: [
        {
          bool: false,
        },
      ],
      showGroup: [
        {
          bool: true,
        },
      ],
    };
  },
  methods: {
    ...mapActions([
      "all_avoirs",
      "all_entreprises",
      "email_acompte",
      "download_avoir",
    ]),

    customLabel({ title }) {
      return `${title}`;
    },
    activateReadMore(y) {
      this.readActivated[y].bool = !this.readActivated[y].bool;
    },
    creategroup() {
      for (
        var i = 0;
        i < this.avoirDetail.document.groupeLigneDocument.length;
        i++
      ) {
        this.showGroup.push({
          bool: true,
        });
        this.readActivated.push({
          bool: false,
        });
      }
    },

    closeGroup(x) {
      this.showGroup[x].bool = !this.showGroup[x].bool;
    },

    moment: function (date) {
      return moment(new Date(date)).locale(this.getSelectedLang.iso_code);
    },

    updateSearch(s) {
      this.page = 1;
      this.search = s;
    },

    selectentreprise(query) {
      if (query) {
        this.filtre_entreprise = query;
      } else {
        this.filtre_entreprise = {
          lib: null,
        };
      }
      this.setup(true);
      this.hideModal("entrepriseModal");
    },

    async searchPiece() {
      await this.all_avoirs({
        search: this.search,
        page: this.page,
        per_page: this.perPage,
        entreprise: this.filtre_entreprise.id,
      });
    },
    async triepiece(bool = null) {
      this.favoris = bool;
      if (bool != null) {
        await this.all_avoirs({
          page: this.page,
          per_page: this.perPage,
          favoris: bool,
          search: this.search,
          with_default: this.isSuperAdmin ? null : 1,
        });
      } else {
        this.searchPiece();
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide();
    },
    handleAcompte(CAT) {
      this.avoirDetail = { ...CAT };
      this.creategroup();
      this.$refs["acompteModel"].show();
    },

    openfac() {
      var facture = this.avoirDetail.document.facture;
      this.$router.push("/facture/list?id=" + facture.id);
    },
    openAcompte() {
      this.$router.push("/acompte/list?id=" + this.avoirDetail.acompte_id);
    },

    sendEmail() {
      this.email_acompte(this.avoirDetail.id).then(() => {
        this.box = "";
        this.$bvModal.msgBoxOk(this.$t("FACTURE_SENDED"), {
          title: "Confirmation",
          size: "sm",
          buttonSize: "sm",
          okVariant: "success",
          headerClass: "p-2 border-bottom-0",
          centered: true,
        });
      });
    },

    generate() {
      this.download_avoir(this.avoirDetail.id).then((response) => {
        this.pdfInfo = response;
        this.download();
      });
    },
    download() {
      window.open(this.downloadUrl + this.pdfInfo, "_blank");
    },

    totalHT(item) {
      const totalSansRemise = item.unit_price * item.quantite;
      if (Math.round(totalSansRemise * 100) / 100 < 0) return 0;
      return Math.round(totalSansRemise * 100) / 100;
    },

    totalAR(item) {
      const totalSansRemise = this.totalHT(item);
      var totalAvecRemise = totalSansRemise;
      if (item.remise_type == "remise.HT")
        totalAvecRemise -= item.remise_value ? +item.remise_value : 0;
      else totalAvecRemise -= (item.remise_value * totalSansRemise) / 100;

      if (totalAvecRemise < 0) return 0;
      return Math.round(totalAvecRemise * 100) / 100;
    },

    pagination(paginate) {
      this.page = paginate;
      this.searchPiece();
    },

    setup(refresh = false) {
      if (refresh) this.page = 1;
      this.search = null;
      this.currententreprise = this.filtre_entreprise;
      this.$refs["entrepriseModal"].hide();

      if (this.getOnlineUser) {
        if (this.isSuperAdmin) {
          this.all_avoirs({
            page: this.page,
            per_page: this.perPage,
            entreprise: this.filtre_entreprise.id,
          });
        } else {
          this.all_avoirs({
            page: this.page,
            per_page: this.perPage,
            entreprise: this.getOnlineUser.entreprise.id,
          });
        }
      }
    },
  },

  mounted() {
    this.setup(true);
    if (this.isSuperAdmin)
      this.all_entreprises({
        page: this.page,
        per_page: 1000,
        search: this.search,
      });
  },

  computed: {
    ...mapGetters([
      "getAllavoirs",
      "getTotalRowavoir",
      "getAvoirLoading",
      "getTabs",
      "getOnlineUser",
      "getSelectedLang",
      "getAllentreprises",
      "getentrepriseLoading",
    ]),

    isSuperAdmin() {
      return this.getOnlineUser.role == "user.super-admin";
    },
    isentrepriseAdmin() {
      return this.getOnlineUser.role == "user.entreprise-admin";
    },
    isAssistant() {
      return this.getOnlineUser.role == "user.assistant";
    },
    isCommercial() {
      return this.getOnlineUser.role == "user.commercial-entreprise";
    },

    prixHTGroup() {
      return function (groupe) {
        var ht = 0;
        this.avoirDetail.document.GroupeLigneDocument_produit.forEach(
          (product) => {
            if (groupe.id == product.groupelignedocument_id) {
              ht = ht + parseFloat(product.quantite) * product.unit_price;
            }
          }
        );
        return ht;
      };
    },
    TVAGroup() {
      return function (groupe) {
        var tva = {};
        this.avoirDetail.document.GroupeLigneDocument_produit.forEach(
          (product) => {
            if (groupe.id == product.groupelignedocument_id) {
              let ht = 0;
              ht = ht + parseFloat(product.quantite) * product.unit_price;
              if (tva[product.tva]) {
                tva[product.tva] += (ht * product.tva) / 100;
              } else {
                tva[product.tva] = (ht * product.tva) / 100;
              }
            }
          }
        );

        return Object.keys(tva).map(function (key) {
          return {
            tva: key,
            value: Math.round(tva[key] * 100) / 100,
          };
        });
      };
    },

    TVA() {
      var tva = {};
      this.avoirDetail.document.GroupeLigneDocument_produit.forEach(
        (product) => {
          let ht = 0;
          ht = ht + parseFloat(product.quantite) * product.unit_price;

          if (tva[product.tva]) {
            tva[product.tva] += (ht * product.tva) / 100;
          } else {
            tva[product.tva] = (ht * product.tva) / 100;
          }
        }
      );
      return Object.keys(tva).map(function (key) {
        return {
          tva: key,
          value: Math.round(tva[key] * 100) / 100,
        };
      });
    },

    TOTALGROUP() {
      return function (groupe) {
        var tva = 0;
        let totaleHT = 0;
        this.avoirDetail.document.GroupeLigneDocument_produit.forEach(
          (product) => {
            if (groupe.id == product.groupelignedocument_id) {
              let ht = 0;

              ht = ht + parseFloat(product.quantite) * product.unit_price;

              tva += (ht * product.tva) / 100;

              totaleHT += ht;
            }
          }
        );
        const ttc = totaleHT + tva;
        return Math.round(ttc * 100) / 100;
      };
    },

    TOTAL() {
      var tva = 0;
      let totaleHT = 0;
      this.avoirDetail.document.GroupeLigneDocument_produit.forEach(
        (product) => {
          let ht = 0;

          ht = ht + parseFloat(product.quantite) * product.unit_price;

          tva += (ht * product.tva) / 100;

          totaleHT += ht;
        }
      );
      const ttc = totaleHT + tva;
      return Math.round(ttc * 100) / 100;
    },
    RESTE() {
      var rest = 0;
      var tva = 0;
      let totaleHT = 0;
      this.avoirDetail.document.GroupeLigneDocument_produit.forEach(
        (product) => {
          let ht = 0;

          ht = ht + parseFloat(product.quantite) * product.unit_price;

          tva += (ht * product.tva) / 100;

          totaleHT += ht;
        }
      );
      const ttc = totaleHT + tva;

      rest = ttc;
      if (+this.avoirDetail.document.facture.montant_maprimrenov)
        rest -= parseFloat(
          +this.avoirDetail.document.facture.montant_maprimrenov
        );
      if (+this.avoirDetail.document.facture.montant_cee)
        rest -= parseFloat(+this.avoirDetail.document.facture.montant_cee);

      if (+this.avoirDetail.document.facture.remise_value) {
        if (
          this.avoirDetail.document.facture.remise_type == "remise.pourcentage"
        ) {
          rest -=
            (rest * +this.avoirDetail.document.facture.remise_value) / 100;
        } else {
          rest -= parseFloat(+this.avoirDetail.document.facture.remise_value);
        }
      }

      return rest;
    },
  },

  filters: {
    financial: function (value, noSymbol = false) {
      return formatMoney(value, {
        symbol: noSymbol ? "" : "€",
        thousand: " ",
        precision: 2,
        decimal: ",",
        format: "%v %s",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.double {
  button {
    width: 48%;
  }
}

.grey {
  background-color: #f5f6f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
  width: 32px;
  margin: 0 auto;
}

.content {
  .content-header {
    .input-group {
      width: 100%;
      position: relative;
      margin-bottom: 0;
      .icon-search {
        @media only screen and (max-width: 900px) {
          display: none;
        }
        overflow: visible;
        position: absolute;
        left: 15px;
        top: 10px;
        color: #515151;
      }

      .icon-balance {
        cursor: pointer;
        overflow: visible;
        position: absolute;
        right: 15px;
        top: 10px;
        color: #28367a;
        outline: 0;

        .balance {
          color: #28367a;
        }
      }
      .form-control::placeholder {
        color: #515151;
        font-size: 12px;
      }
    }

    .filtre {
      @media only screen and (max-width: 900px) {
        width: 39%;
      }
      width: 19%;
      background-color: #28367a;
      color: #fff;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 4px;
      border-radius: 5px;
      font-size: 12px;
    }
  }

  & p {
    font-size: 12px;
    font-weight: 500;
    margin: 8px 15px 0 0;
  }
}

.body {
  padding: 15px;
  box-shadow: 1px 1px 24px #00000019;
  max-height: 60vh;
  overflow: auto;

  ul {
    list-style: none;
    & li {
      padding: 8px 0;
      cursor: pointer;
      background-color: #f8f8f8;
      margin-bottom: 4px;
      color: #000;
      border: none;
      box-shadow: none;
      transition: all 0.2s ease;
      text-align: center;

      &:hover {
        background-color: #28367a;
        color: #fff;
      }
    }
  }
}
.justify {
  padding: 13px;
  .left {
    h2 {
      color: #28367a;
      font-weight: 700;
      margin-bottom: 0px;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
    .title {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .block {
      margin-top: 10px;
    }
  }
  .right {
    .infonum {
      display: grid;
    }
    .span {
      color: #515151;
    }
    h2 {
      color: #28367a;
      font-weight: 700;
    }
    .adressinfo {
      display: grid;
    }
    .info {
      color: #515151;
    }
    .entrepriseinfo {
      color: #000;
      font-weight: 600;
    }
  }

  .divborder {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    overflow: hidden;
    color: #28367a;
    width: 100%;
    p {
      margin: 0px 10px;
    }
  }
  .divborder::after,
  .divborder::before {
    content: "";
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
    height: 1px;
    background-color: #d8d8d8;
  }
}

.w-100 {
  background-color: #f8f8f8;
  padding: 4px;
  overflow-x: auto;
}

body {
  background: #fafdff;
}

.detailClient {
  background-color: #fff;

  box-shadow: 1px 1px 24px #00000019;

  .clientTop {
    padding: 16px 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    border-bottom: 1px solid #707070;

    & .prodimg {
      max-width: 185px;
      max-height: 231px;
    }

    .star {
      color: #ffc400;
      position: absolute;
      top: 12px;
      right: 8px;
      font-size: 30px;
    }
  }

  .clientMid {
    padding: 16px 8px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    border-bottom: 1px solid #707070;

    & > div {
      width: 50%;
    }
  }

  .clientBot {
    padding: 16px 8px;
  }

  & h4 {
    color: #28367a;
    font-size: 18px;
    font-weight: 600;
    margin: 0;
  }

  & p {
    color: #515151;
    font-size: 16px;
    font-weight: 500;
  }
}
.filtre {
  display: flex;
  align-items: center;

  .productTrie {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    padding: 4px 8px;
    border-radius: 32px;
    background-color: #34c38f;
    color: #fff;
    margin: 2px 0 !important;

    .icons {
      cursor: pointer;
      width: 18px;
      height: 18px;
      margin-left: 4px;
    }
  }
}

.group {
  box-shadow: 0px 2px 6px #00000019;
  background-color: #fff;
  margin-bottom: 15px;
}

.spinner-border {
  width: 1.2rem;
  height: 1.2rem;
}
.groupEdit {
  border: 1px solid #707070;
  padding: 8px;
  border-radius: 5px;
  margin-bottom: 16px;
  box-shadow: 0 1px 2px 0 #707070;
  width: 100%;

  .entete {
    font-weight: 400;
    font-size: 12px;
    background-color: #28367a;
    padding: 4px;
    color: #fff;
    text-align: center;
    margin-bottom: 8px;
  }
}
.input-group {
  margin-bottom: 8px;
  .icon-search {
    position: absolute;
    left: 15px;
    top: 10px;
  }
}

.cours {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;

  p {
    margin: 0;
    color: #fff;
    background-color: #d0cccc;
    width: 81px;
    height: 24px;
    font-size: 13px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    font-size: 14px;
  }
}
.justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.part {
  padding: 8px 0;
  span {
    font-size: 14px;
    font-weight: 500;
    color: #515151;
    word-wrap: break-word;
  }
  .remarqueblock {
    margin-bottom: 5px;
  }
  .cadre {
    background-color: #f8f8f8;
    margin-top: 1%;
    margin-bottom: 1%;
    box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
    padding: 13px;
  }
  .left {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 30%;
  }

  .right {
    @media only screen and (max-width: 900px) {
      width: 100%;
    }
    width: 30%;
    p {
      float: right;
      padding: 8px;
      background-color: #d0cccc;
      color: #000;
      width: 50%;
      font-weight: 600;
      text-align: center;
      margin: 0 0 4px;
    }
  }

  .fact {
    font-size: 16px;
    font-weight: 600;
    color: #28367a;
  }

  h2,
  h3 {
    font-weight: 600;
  }

  h2 {
    font-size: 16px;
  }
  h3 {
    font-size: 16px;
    margin: 0;
  }

  .part-title {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }

  ul {
    list-style: none;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #515151;

      span {
        display: block;
        width: 50%;
        font-size: 14px;
      }

      .title {
        @media only screen and (max-width: 900px) {
          font-size: 12px;
        }
        color: #393939;
        font-weight: 600;
        font-size: 14px;
      }
    }

    .traveauxtitle {
      @media only screen and (max-width: 900px) {
        padding: 0;
      }
      padding: 0 15px;
    }
  }
  .flexEnd {
    display: flex;
    justify-content: space-between;
    .sousTraitant {
      p {
        color: #2167d9;
      }
    }
    .subtable {
      .complet {
        @media only screen and (max-width: 900px) {
          margin-right: 10%;
          width: 100%;
        }
        border-collapse: separate;
        margin-right: 10%;
        width: 100%;
        height: 32px;
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        tr {
          height: 24px;
        }

        .thead {
          @media only screen and (max-width: 900px) {
            padding: 6px !important;
          }
          background-color: #6472b3;
          color: #fff;
          max-width: 118px;
          height: 32px;
        }
        td {
          color: #000;
          background-color: #e9eaf2;
          width: 118px;
        }
      }
    }
  }

  .tabledonne {
    overflow-x: auto;
    margin-bottom: 4px;
    .libblock {
      font-weight: 800;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  table {
    border-collapse: separate;
    th {
      font-weight: 400;
      font-size: 12px;
      background-color: #6472b3;
      padding: 4px;
      color: #fff;
      border: 1px solid #6472b3;
      text-align: center;
    }
    td {
      text-align: center;
      padding: 4px;
      background-color: #e9eaf2;
    }
    tr {
      height: 24px;
    }
  }
}

.flexEndTotale {
  display: flex;
  justify-content: flex-end;
  .subtableTotale {
    .completTotale {
      @media only screen and (max-width: 900px) {
        margin-right: 10%;
        width: 100%;
      }
      border-collapse: separate;
      margin-right: 10%;
      width: 100%;
      height: 32px;
      text-align: center;
      font-size: 12px;
      font-weight: bold;
      tr {
        height: 24px;
      }

      .theadTotale {
        @media only screen and (max-width: 900px) {
          padding: 6px !important;
        }
        background-color: #28367a;
        color: #fff;
        max-width: 118px;
        height: 32px;
      }
      td {
        color: #000;
        background-color: #e9eaf2;
        width: 135px;
      }
    }
  }
}

.buttondevis {
  @media only screen and (max-width: 900px) {
    width: 100%;
    padding: 10px 0;
    justify-content: center;
    flex-wrap: wrap;
  }
  width: 95%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-around;
  padding: 20px 0;
  border-top: 1px solid #d8d8d8;
  gap: 15px 0;

  button {
    @media only screen and (max-width: 900px) {
      min-width: 47%;
      max-width: 47%;
      font-size: 12px;
      margin: 4px 4px 0 0;
      padding: 0;
    }
    min-width: 24%;
    height: 42px;
    font-size: 14px;

    .block-spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.group {
  @media only screen and (max-width: 900px) {
    overflow: hidden;
    padding: 8px;
  }
  box-shadow: 0px 2px 6px #00000019;
  background-color: #fff;
  border-radius: 0 0 5px 5px;
  margin-bottom: 15px;
  .part {
    padding: 15px;
    margin-bottom: 0;
    overflow: auto;
  }
}
.piece {
  background-color: #28367a;
  color: #fff;
  padding: 0px 15px;
  border-radius: 5px 5px 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;

  div:first-child {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  p {
    margin: 0;
    font-size: 14px;
    margin-right: 8px;
    overflow: hidden;
  }
  .icone-down-up {
    display: flex;
    align-items: center;
    p,
    span {
      @media only screen and (max-width: 900px) {
        display: none;
      }
    }

    button {
      background-color: transparent !important;
      border: transparent;
      box-shadow: none;
    }

    span {
      font-size: 14px;
      background-color: #6472b3;
      border-radius: 4px;
      padding: 2px 12px;
    }
  }
}

.modal-test {
  width: 95%;
  margin: auto;
}
.description-container {
  font-size: 14px;
  color: #515151;
  .title-description {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }
}
.tabledonne td.blocklibdesc {
  text-align: left;

  .descblock {
    white-space: normal;
    max-width: 400px;
    height: 72px;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.575;
    position: relative;
  }
  .read {
    position: absolute;
    bottom: -2px;
    right: 0;
    display: inline-block;
    font-size: 12px;
    font-weight: 800;
    color: #28367a;
  }
}
.description-container {
  font-size: 14px;
  color: #515151;
  .title-description {
    color: #28367a;
    font-weight: 700;
    margin-right: 10px;
  }
}
.textstyle {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.auto-heigth {
  height: auto !important;
}

.accompteTable {
  td {
    background: #e9eaf2;
    border: 1px solid #fff;
  }
}
</style>
